import classNames from 'classnames';
import { Modal } from 'components/2-molecules/Modal';
import { useEffect, useRef, useState } from 'react';
import styles from './RichText.module.scss';

export interface RichTextModal {
	modalId: string;
	header?: string;
	body?: React.ReactNode;
}
export interface RichTextProps {
	className?: string;
	content: string;
	style?: 'center';
	modals?: RichTextModal[];
	modalTrackingCallback?: (modalName: string) => void;
}

export const RichText: React.FC<RichTextProps> = ({ content, className, style, modals, modalTrackingCallback }) => {
	const [modalVisible, setModalVisible] = useState(false);
	const [modalContent, setModalContent] = useState<RichTextModal>(null);
	const rtfContainer = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (!modals?.length) return;
		const handleTrigger = (e) => {
			const { modalTrigger } = e.target.dataset ?? {};

			const selectedModal = modals.find((modal) => modal.modalId === modalTrigger);
			if (!selectedModal) return setModalVisible(false);
			const modalName = `${selectedModal.header ? `${selectedModal.header} ` : ''}${selectedModal.modalId}`;
			modalTrackingCallback(modalName);

			setModalContent(selectedModal);
			return setModalVisible(true);
		};

		const handleClick = (e) => {
			e.preventDefault();
			handleTrigger(e);
		};
		const handleKeyPress = (e) => {
			e.preventDefault();
			if (e.code !== 'Space') return;
			handleTrigger(e);
		};
		const modalTriggers = rtfContainer.current.querySelectorAll('[data-modal-trigger]');
		modalTriggers.forEach((elm: HTMLElement) => {
			elm.addEventListener('click', handleClick);
			elm.addEventListener('keypress', handleKeyPress);
		});
		return () =>
			modalTriggers.forEach((elm) => {
				elm.removeEventListener('click', handleClick);
				elm.removeEventListener('keypress', handleKeyPress);
			});
	}, [modals, rtfContainer]);

	return (
		<>
			<div
				className={classNames(styles.RichText, styles[`RichText___${style}`], className)}
				dangerouslySetInnerHTML={{ __html: content }}
				ref={rtfContainer}
			/>
			{modals?.length ? (
				<Modal show={modalVisible} header={modalContent?.header} onClose={() => setModalVisible(false)}>
					{modalContent?.body}
				</Modal>
			) : null}
		</>
	);
};
